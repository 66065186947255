import inspector from "@/role/inspector";
import company from "@/role/company";
import lawyer from "@/role/lawyer";
import government from "@/role/government";
import moderator from "@/role/moderator";
import admin from "@/role/admin";
import active from "@/role/active";
import defaultRole from "@/role/defaultRole";

export const getUserRole = (role) => {
    let userRole = {}
    switch (role) {
        case 'Active':
            userRole = active
            break;
        case 'Inspector':
            userRole = inspector
            break;
        case 'Company':
            userRole = company
            break;
        case 'Lawyer':
            userRole = lawyer
            break;
        case 'Government':
            userRole = government
            break;
        case 'Moderator':
            userRole = moderator
            break;
        case 'Admin':
            userRole = admin
            break;
        case null:
            userRole = defaultRole
            break;
        default:
            break
    }
    return userRole
}

export const getUserAvatar = (avatar, url) => {
    let prodPath = 'https://xn--e1aabhcpqfgk.xn--j1agca3a5c.xn--p1ai';
    let devPath = 'https://xn--b1add.xn--e1aabhcpqfgk.xn--j1agca3a5c.xn--p1ai';

    if (url && avatar) {
        if(window.location.origin !== prodPath) {
            return `${devPath}/public/upload/avatar/${avatar}`
        } else {
            return `${prodPath}/public/upload/avatar/${avatar}`
        }
    }

    return avatar || require('@/assets/images/user-default-avatar.png')
}


